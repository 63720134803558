// @flow strict
import React from 'react';
import Author from './Author';
import Contacts from './Contacts';
import Copyright from './Copyright';
import Menu from './Menu';
import styles from './Sidebar.module.scss';
import { useSiteMetadata } from '../../hooks';
import addToMailchimp from 'gatsby-plugin-mailchimp'

type Props = {
  isIndex?: boolean,
};

const Sidebar = ({ isIndex }: Props) => {
  const { author, copyright, menu } = useSiteMetadata();

  var email = ''

  function _handleChange(e)
  {
    email = e.target.value
  }

  function _handleSubmit(e)
  {
    e.preventDefault();

    console.log('submit', email);

    addToMailchimp(email)
      .then(({ msg, result }) => {
        console.log('msg', `${result}: ${msg}`);

        if (result !== 'success') {
          throw msg;
        }
        alert("Thanks! You're on the list!");
      })
      .catch((err) => {
        console.log('err', err);
        alert(err);
      });
  }

  return (
    <div className={styles['sidebar']}>
      <div className={styles['sidebar__inner']}>
        <Author author={author} isIndex={isIndex} />
        <Menu menu={menu} />
        <Contacts contacts={author.contacts} />
        <div align="center"><p>Sometimes I send emails:<form onSubmit={_handleSubmit}>
        <input type="email" defaultValue="" placeholder='Email' name="email" id="email" onChange={_handleChange} />
        <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" />
      </form></p>
      </div>
        <Copyright copyright={copyright} />
      </div>
    </div>
  );
};

export default Sidebar;
